import i18next from "i18n";
import React from "react";
import { Alert } from "react-bootstrap";

export const warnings = [
  i18next.t("expenses.split.warning.mustBeSave"),
  i18next.t("expenses.split.warning.makesManualInputedExpense"),
  i18next.t("expenses.split.warning.resetTaxCategory"),
];

/** 警告メッセージ */
export const WarningMessage: React.FC = () => {
  return (
    <Alert bsStyle="warning" style={{ margin: "16px 0" }}>
      <ul style={{ paddingLeft: "16px" }}>
        {warnings.map((warning) => {
          return <li key={warning}>{warning}</li>;
        })}
      </ul>
    </Alert>
  );
};
