import BootstrapCard from "components/BootstrapCard";
import i18next from "i18n";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../exports/actions/index";
import ExportJobModal from "../../exports/components/ExportJobModal";
import CategoryAnalysisTable from "../components/CategoryAnalysisTable";

/**
 * 勘定科目毎集計
 */
export class CategoryAnalysisCard extends Component {
  constructor(props) {
    super(props);

    this.onDownloadClick = this.onDownloadClick.bind(this);
  }

  /**
   * 集計出力ボタンを押した
   */
  onDownloadClick() {
    const { openExportModal } = this.props;

    openExportModal();
  }

  render() {
    const { closeExportModal, isShowModal } = this.props;
    const buttons = this.props.isDownloadable
      ? [
          {
            color: "accent",
            icons: [
              {
                position: "right",
                className: "fa-angle-right",
              },
            ],
            content: i18next.t("exports.titles.aggregationByCategoryExport"),
            onClick: this.onDownloadClick,
          },
        ]
      : [];

    return (
      <BootstrapCard
        title={i18next.t(
          "paymentRequests.analysisDetail.aggregationByExpenseCategory.title",
        )}
        titleIcon={{ className: "fa-tags" }}
        buttons={buttons}
      >
        <CategoryAnalysisTable summaries={this.props.summariesByCategory} />
        <ExportJobModal
          title={i18next.t("exports.titles.fileExport")}
          show={isShowModal}
          closeModal={closeExportModal}
          formName="CategoryAnalysisExportForm"
        />
      </BootstrapCard>
    );
  }
}

CategoryAnalysisCard.defaultProps = {
  isDownloadable: true,
};

CategoryAnalysisCard.propTypes = {
  isDownloadable: PropTypes.bool.isRequired,
  summariesByCategory: PropTypes.array.isRequired,
  isShowModal: PropTypes.bool.isRequired,
  openExportModal: PropTypes.func.isRequired,
  closeExportModal: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  const { analysis, exportData } = state;
  return {
    summariesByCategory: analysis.summariesByCategory,
    isShowModal: exportData.showAggregationByCategoryModal,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    openExportModal() {
      dispatch(actions.openAggregatonByCategoryModal());
    },
    closeExportModal() {
      dispatch(actions.closeAggregatonByCategoryModal());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CategoryAnalysisCard);
