import { Field } from "applications/transactions/types/EntryForms/Field";
import { ListForm } from "components/renewaled_ui/form_views";
import i18next from "i18n";
import React from "react";
import { resetValidateMessage } from "utilities/Utils";
import DestinationField from "../components/DestinationField";
import FareSearchBox from "./FareSearchBox";

interface RouteInputFieldProps {
  destination: string | null | undefined;
  ownerId?: string;
  onDestinationChange: (text: string) => void;
  onDestinationSelect: (selectedItem: string) => void;
  transactedAt?: string;
  companions?: string[];
  fields?: Field[];
}

const RouteInputField: React.FC<RouteInputFieldProps> = ({
  destination,
  ownerId,
  onDestinationChange,
  onDestinationSelect,
  transactedAt,
  companions,
  fields,
}) => {
  const handleTextChange = (text: string): void => {
    resetValidateMessage(`.destination-input-field`);
    onDestinationChange(text);
  };

  return (
    <>
      <ListForm
        key="destination_input_list"
        label={i18next.t("transactions.properties.visited")}
        required
      >
        <DestinationField
          key="destination_input"
          text={destination}
          ownerId={ownerId}
          onTextChange={handleTextChange}
          onSelect={onDestinationSelect}
        />
      </ListForm>
      <FareSearchBox
        ownerId={ownerId}
        transactedAt={transactedAt}
        companions={companions}
        fields={fields}
      />
    </>
  );
};

export default RouteInputField;
